(function () {
    'use strict';

    angular.module('informaApp')
        .factory('NewFeatureViewResolver', NewFeatureViewResolver);

    function NewFeatureViewResolver(NewFeatureService) {
        return function (featureId) {
            return Number.isInteger(featureId)
                ? NewFeatureService.getById(featureId).then(feature => ({feature}))
                : NewFeatureService.getAllNotRemoved().then(featureList => ({featureList}));
        }
    }
})();
